<template>
    <div>
        <div class="main-header">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 main-header text-left" :style="{  bgColor }">
                        <a href="/">

                        <img
                            :src="logo_path"
                            style="height: 50px; text-align: center"
                            alt=""
                            align="middle"
                            v-if='logo_path!=null'
                        />

                        <img
                            src="/media/logos/yelkoedu_main_logo.png"
                            style="height: 50px; text-align: center"
                            align="middle"
                            alt="Yeldemy Logo"
                            v-else
                        />
                        </a>
<!--                        <span style="color: #ffffff; font-size: 22px; margin-left: 10px">Online admission system</span>-->
                     </div>
                    <div class="col-md-6 text-right">
<!--                        <p v-if="setting"-->
<!--                            class="font-weight-bolder font-size-h4 font-size-h1-lg"-->
<!--                            :style="collegeColor"-->
<!--                        >-->
<!--                            {{setting.college_name || 'Online admission portal'}}-->
<!--                        </p>-->
<!--                        <span-->
<!--                            class="font-weight-bolder font-size-h5"-->
<!--                            :style="sloganColor"-->
<!--                        >-->
<!--                            {{setting.slogan || ''}}-->
<!--                        </span>-->
                    </div>
                </div>
            </div>

        </div>

        <v-container class="main-form">

            <div class="row">

                <div class="col-md-12 bg-white">
                    <!--            &lt;!&ndash;begin::Aside&ndash;&gt;-->
                    <!--            <div-->
                    <!--                    class="login-aside d-flex flex-column align-items-center justify-content-center"-->
                    <!--                    :style="{ backgroundImage: `url(${backgroundImage})`, bgColor }"-->
                    <!--            >-->
                    <!--                <div class="overlay-bg" :style="{backgroundColor: bgColor}"></div>-->
                    <!--                <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-15">-->
                    <!--                    <a href="#" class="text-center mb-10">-->
                    <!--                        <img-->
                    <!--                                :src="logo_path"-->
                    <!--                                class="max-h-70px"-->
                    <!--                                alt="College"-->
                    <!--                                v-if='logo_path!=null'-->
                    <!--                        />-->

                    <!--                        <img-->
                    <!--                                src="/media/logos/yelkoedu_main_logo.png"-->
                    <!--                                class="max-h-70px"-->
                    <!--                                alt="Yelko Education Logo"-->
                    <!--                                v-else-->
                    <!--                        />-->

                    <!--                    </a>-->
                    <!--                    <h3 v-if="setting"-->
                    <!--                        class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"-->
                    <!--                        :style="collegeColor"-->
                    <!--                    >-->
                    <!--                        {{setting.college_name || 'Yelko Education Application'}}-->
                    <!--                    </h3>-->
                    <!--                    <h3 v-if="setting"-->
                    <!--                            class="font-weight-bolder text-center font-size-h5"-->
                    <!--                            :style="sloganColor"-->
                    <!--                    >-->
                    <!--                        {{setting.slogan || 'Complete Education Management System'}}-->
                    <!--                    </h3>-->
                    <!--                </div>-->
                    <!--&lt;!&ndash;                <div&ndash;&gt;-->
                    <!--&lt;!&ndash;                        class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"&ndash;&gt;-->
                    <!--&lt;!&ndash;                        :style="{ backgroundImage: `url(${backgroundImage})` }"&ndash;&gt;-->
                    <!--&lt;!&ndash;                ></div>&ndash;&gt;-->
                    <!--            </div>-->
                    <!--            &lt;!&ndash;begin::Aside&ndash;&gt;-->

                    <!--begin::Content-->
                    <router-view :setting="setting"></router-view>


                    <!--end::Content-->
                </div>
                <vue-snotify></vue-snotify>
            </div>
        </v-container>
<!--        <div class="row main-footer" >-->
<!--            <div class="container">-->
<!--                <div class="row">-->
<!--                    <div class="col-md-12 text-center" :style="collegeColor">-->
<!--                        {{setting.college_name || 'Online admission portal'}}-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->

<!--        </div>-->
    </div>

</template>

<!-- Load login custom page styles -->

<script>
    import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
    import {mapState} from "vuex";

    const siteSettingService = new SiteSettingService();

    export default {
        name: "author",
        data() {
            return {
                setting: null,
                login_bg_path: null,
                login_bg_color: null,
                college_color: null,
                slogan_color: null,
                logo_path: null,
            }
        },

        methods: {
            getColors() {
                siteSettingService.getColorsVariables().then((response) => {
                    this.setting = response.data;
                    if (this.setting && this.setting.id) {
                        if (this.setting.colors_variables) {
                            this.colors_variables = JSON.parse(this.setting.colors_variables);
                            this.setStyleVariable();
                        }
                        if (this.setting.login_bg_path && this.setting.login_bg_path['real']) {
                            this.login_bg_path = this.setting.login_bg_path['real'];
                        }
                        if (this.setting.logo_path && this.setting.logo_path['real']) {
                            this.logo_path = this.setting.logo_path['real'];
                        }
                        if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                            this.login_bg_color = this.setting.colors_variables_json_values['login']['bg_color'];
                        }
                        if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                            this.college_color = this.setting.colors_variables_json_values['login']['primary_color'];
                        }
                        if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                            this.slogan_color = this.setting.colors_variables_json_values['login']['secondary_color'];
                        }
                            this.handleIcon()

                    }
                }).catch(err => {
                })
            },
            handleIcon() {
                // accessing the link tag

                if (this.setting.fav_icon_path && this.setting.fav_icon_path['real']) {
                    const favicon = document.createElement("link");
                    favicon.rel = 'icon'
                    document.head.append(favicon)
                    favicon.href = this.setting.fav_icon_path['real'];
                }

                if (this.setting.college_name) {
                  document.title = this.setting.college_name+' Online Admission System' ;
                }
            },
            setStyleVariable() {

                document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
                document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
                document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
                document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

                document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
                document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
                document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
                document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);

                document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
                document.documentElement.style.setProperty( '--button-secondary-color', this.colors_variables.button.secondary_color);
                document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
                document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
                document.documentElement.style.setProperty('--login-bg-color', this.colors_variables.login.bg_color);
            },
        },
        beforeMount() {
            this.getColors();
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            backgroundImage() {

                if (this.login_bg_path) {
                    return this.login_bg_path;
                }
                return process.env.BASE_URL + "/";
            },
            bgColor() {

                if (this.login_bg_color) {
                    return "background-color:" + this.login_bg_color;
                }
                return "background-color: #000000";
            },
            collegeColor() {
                if (this.college_color) {
                    return "color:" + this.college_color;
                }
                return "color: #FFFFFF";
            },
            sloganColor() {
                if (this.slogan_color) {
                    return "color:" + this.slogan_color;
                }
                return "color: #FFFFFF";
            }
        }
    };
</script>

<style lang="scss" scope>
    .login-aside {
        position: relative;
        z-index: 1;
        background-size: cover;
        flex: 1.5 !important;

        & > * {
            position: relative;
            z-index: 3;
        }

        .overlay-bg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0.75;
            background-color: var(--login-bg-color);
        }

        /*&:after {*/
        /*    content: '';*/
        /*    position: absolute;*/
        /*    left: 0;*/
        /*    top: 0;*/
        /*    z-index: 2;*/
        /*    width: 100%;*/
        /*    height: 100%;*/
        /*    background-color: rgb(4, 69, 140, 0.7);*/
        /*}*/

        & + * {
            flex: 2 !important;
        }
    }
</style>
